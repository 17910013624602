import { LatLngBounds, LatLngExpression, LatLng } from 'leaflet';
import * as Wellknown from 'wellknown';

export default class GeoUtil {
    static latLngBoundsFromPolygonWKT(wkt: any): LatLngBounds {
        if (wkt === undefined) {
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }

        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
            }
            const north = parsedWKT.coordinates[0][1][1];
            const east = parsedWKT.coordinates[0][1][0];
            const south = parsedWKT.coordinates[0][3][1];
            const west = parsedWKT.coordinates[0][3][0];

            const northEast = new LatLng(north, east);
            const southWest = new LatLng(south, west);
            return new LatLngBounds(northEast, southWest);
        } catch (e) {
            console.log('Error parsing polygon WKT for bounding box');
            console.log(e);
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }
    }
}