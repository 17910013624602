import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import { LatLngBounds } from "leaflet";
import "./App.css";
import { request } from "./api/api";
import { ListingDTO } from "./api/model";
import GeoUtil from "./lib/geo-util";

function App() {
    const [listing, setListing] = React.useState<ListingDTO | undefined>(undefined);
    const [bounds, setBounds] = React.useState<LatLngBounds | undefined>(undefined);

    useEffect(() => {
        request<ListingDTO>("https://api.soar.earth/v1/listings/15336", { headers: { subdomain: "soar.earth" } }).then(
            (response) => setListing(response)
        );
    }, []);

    useEffect(() => {
      if(listing) {
        setBounds(GeoUtil.latLngBoundsFromPolygonWKT(listing.geometryWKT));
      }
    }, [listing]);

    return (
        <div className="App">
            <MapContainer center={[50.5, 30.5]} zoom={6} style={{ width: "100%", height: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {listing && <TileLayer
                    key={listing.id}
                    url={listing.tileUrl}
                    tms={true}
                    bounds={bounds}
                    zIndex={1000}
                />}
            </MapContainer>
        </div>
    );
}

export default App;
